import React from "react";

function Siteplan() {
  return (
    <div className="row">
      <div className="col px-0">
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            title="siteplan"
            className="embed-responsive-item"
            src={process.env.REACT_APP_SITEPLAN_URL}
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
}

export default Siteplan;
