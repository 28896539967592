import { API, Storage } from "aws-amplify";
import { v1 as uuidv1, v4 as uuidv4 } from "uuid";

export const S3UploadFile = async (
  file,
  company_id,
  user_id,
  submission_id,
  field
) => {
  const folder = field === "payment_document" ? "payment" : "identities";
  const res = await Storage.put(
    `companies/${company_id}/created_by/${user_id}/customers/${submission_id}/${folder}/${uuidv4()}/${field}_${uuidv1()}`,
    file,
    {
      contentType: file.type,
    }
  );
  return res;
};

export const S3ReadFile = async (key) => {
  // const res = await Storage.get(`companies/23fd0401-1de5-4768-a5af-dc2000e8e3cf/created_by/+60169973297/customers/6b61b7ec-5310-436c-ad7e-253092a50e69/identities/f1ffb729-9067-44f2-9fca-d664382a47d1/icfront_8a0a7160-99d7-11ea-b62d-d5fce5524076`);
  const res = await Storage.get(key);
  return res;
};

export async function getCustomer(id) {
  let res;
  try {
    res = await API.get("mpLeads", `/customers/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (err) {
    console.log("@error", err);
    return err.response.data;
  }
  return res;
}

export async function putCustomer(id, data) {
  console.log("Putting Data", data);
  let res;
  try {
    res = await API.put("mpLeads", `/customers/${id}`, {
      body: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (err) {
    return { error: err.response.data };
  }
  console.log("@res", res);
  return res;
}

export async function createCustomer(data, company_id) {
  let res;
  try {
    res = await API.post("mpLeads", `/customers`, {
      body: { ...data, company_id },
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (err) {
    console.log("@create customer error", err);
    return { error: err.response.data };
  }
  return res;
}

export async function getOpportunities(id) {
  let res;
  try {
    res = await API.get("mpLeads", `/customers/${id}/opportunities`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (err) {
    console.log("@error", err);
    return err.response.data;
  }
  return res;
}

export async function createOpportunity(
  primary_customer_id,
  primary_purchase_email,
  primary_purchaser_name,
  company_id,
  customer_ids
) {
  let res;
  try {
    res = await API.post("mpLeads", `/opportunities`, {
      headers: {
        "Content-Type": "application/json",
        "X-Email": primary_purchase_email,
        "X-Preferred-Name": primary_purchaser_name,
      },
      body: {
        primary_customer_id,
        project_id: process.env.REACT_APP_PROJECT_ID,
        campaign_id: process.env.REACT_APP_CAMPAIGN_ID,
        form_id: process.env.REACT_APP_FORM_ID,
        project_name: process.env.REACT_APP_PROJECT_NAME,
        source: "srb",
        company_id,
        customer_ids,
      },
    });
  } catch (err) {
    console.log("@error", err);
    return err.response.data;
  }
}
export async function editOpportunity(
  opportunity_id,
  primary_customer_id,
  company_id,
  customer_ids
) {
  let res;
  try {
    res = await API.put("mpLeads", `/opportunities/${opportunity_id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        primary_customer_id,
        project_id: process.env.REACT_APP_PROJECT_ID,
        campaign_id: process.env.REACT_APP_CAMPAIGN_ID,
        form_id: process.env.REACT_APP_FORM_ID,
        project_name: process.env.REACT_APP_PROJECT_NAME,
        source: "srb",
        company_id,
        customer_ids,
      },
    });
  } catch (err) {
    console.log("@error", err);
    return err.response.data;
  }
}

export async function getBookings(company_id, booking_id) {
  let res;
  try {
    res = await API.get("mpLeads", `/bookings/${booking_id}/templates`, {
      headers: {
        "Content-Type": "application/json",
        "x-company-id": company_id,
        "x-user-company-id": company_id,
      },
    });
  } catch (err) {
    console.log("@error", err);
    return err.response.data;
  }
  return res;
}

export async function getBookingTemplate(company_id, booking_id, template_id) {
  let res;
  try {
    res = await API.get(
      "mpLeads",
      `/bookings/${booking_id}/templates/${template_id}/print`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-company-id": company_id,
          "x-user-company-id": company_id,
        },
        responseType: "text",
      }
    );
  } catch (err) {
    console.log("@error", err);
    return err.response.data;
  }
  return res;
}
