import React, { useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Registration, Balloting, Email, UnitSelection } from "../../../images";
import ContactUs from "../../../components/contactUs";
import CountdownTimer from "../../../components/countdownTimer";
import Contexts from "../../../contexts";
import BallotingTime from "../../../components/ballotingTime";
import UnitSelectionTime from "../../../components/unitSelectionTime";

function OnlineBalotting() {
  const { isRegistered } = useContext(Contexts);
  const lockRegistrationButton = moment("2020-07-28");
  const ballotingEndDate = moment("2020-08-09");

  if (ballotingEndDate.isBefore()) {
    return (
      <div className="row" style={{ marginTop: "3rem" }}>
        <div className="col d-flex flex-column">
          <h1 className="title text-center">Online balloting has ended</h1>
          <div className="text-center mt-3">
            <span className="sub-info">
              Online Balloting and Unit Selection for Rimbun 3, Double Storey
              Terrace @ Bandar Kinrara was held on{" "}
              <b> 7th August & 8th August 2020, 11:00AM - 12:00PM</b>
            </span>
          </div>
          <div className="text-center">
            <Link to="/" className="btn grey-outline-button mt-4 py-2">
              Back to All balloting events
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <h1 className="title">
            Online Balloting and Unit Selection for Rimbun 3, Double Storey
            Terrace @ Bandar Kinrara
          </h1>
          <div className="mt-3">
            <BallotingTime />
          </div>
          <div className="mt-3">
            <UnitSelectionTime />
          </div>
        </div>
        {lockRegistrationButton.isAfter() ? (
          <div className="col-md-3 mt-4 mt-md-0 d-flex flex-column">
            {isRegistered ? (
              <>
                <CountdownTimer />
                <div
                  className="btn mt-4 w-100"
                  style={{
                    padding: 13,
                    backgroundColor: "#F4F5F7",
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: 14,
                    color: "#DBDDDE",
                  }}
                >
                  Registered
                </div>
              </>
            ) : (
              <Link
                to={{ pathname: "/1/detail/register" }}
                className="btn red-button text-center my-auto"
              >
                Register for online balloting
              </Link>
            )}
          </div>
        ) : (
          <div className="col-md-3 mt-4 mt-md-0 d-flex flex-column">
            <CountdownTimer />
            <div
              className="btn mt-4 w-100"
              style={{
                padding: 13,
                backgroundColor: "#F4F5F7",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: 14,
                color: "#DBDDDE",
              }}
            >
              Registration is closed
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-12 mt-3 mb-3">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-md-9">
          {isRegistered ? null : (
            <span className="sub-info">
              We are pleased to invite you to register for an entry for{" "}
              <span className="sub-info-bold">
                Online Balloting and Unit Selection for Rimbun 3, Double Storey
                Terrace @ Bandar Kinrara.
              </span>
              <br />
              <br />
              Just follow these steps to own your preferred unit - Rimbun 3,
              Double Storey Terrace @ Bandar Kinrara.
            </span>
          )}
          <div className="mb-4">
            <img
              alt="Registration"
              src={Registration}
              width="25px"
              className="mt-4"
            />
            <div className="d-flex flex-column">
              <span className="sub-info-title">Registration</span>
              <span className="sub-info" style={{ paddingTop: 3 }}>
                Register for an entry by completing the registration form before{" "}
                <b>28th July 2020.</b>
              </span>
            </div>
          </div>
          <div>
            <img alt="Balloting" src={Balloting} width="25px" />
            <div className="d-flex flex-column">
              <span className="sub-info-title">Balloting</span>
              <span className="sub-info" style={{ paddingTop: 3 }}>
                An online balloting session will be conducted on{" "}
                <b>7th August 2020. </b>This session will determine the
                successful balloters who will be allocated a time slot to select
                a unit on unit selection date.
              </span>
            </div>
          </div>
          <div>
            <img
              alt="Email Notification"
              src={Email}
              width="25px"
              className="mt-4"
            />
            <div className="d-flex flex-column">
              <span className="sub-info-title">Email Notification</span>
              <span className="sub-info" style={{ paddingTop: 3 }}>
                An email will be sent to successful registrants stating their
                ballot ID for online balloting.
              </span>
            </div>
          </div>
          <div>
            <img
              alt="Unit Selection"
              src={UnitSelection}
              width="25px"
              className="mt-4"
            />
            <div className="d-flex flex-column">
              <span className="sub-info-title">Unit Selection</span>
              <span className="sub-info" style={{ paddingTop: 3 }}>
                On your unit selection day <b>(8th August 2020)</b> you will need
                to be present at Bandar Kinrara Welcome Centre at the allocated
                time. You will be given <b>15 minutes</b> to select your ideal
                unit.
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-4 mt-md-0">
          <ContactUs />
        </div>
      </div>
    </>
  );
}

export default OnlineBalotting;
