export const amplifyConfig = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_AWS_REGION || "",
    identityPoolId: process.env.REACT_APP_COGNITO_AWS_IDENTITY_POOL_ID || "",
    userPoolId: process.env.REACT_APP_COGNITO_AWS_USER_POOL_ID || "",
    userPoolWebClientId: process.env.REACT_APP_COGNITO_AWS_APP_CLIENT_ID || "",
    cookieStorage: {
      domain: process.env.REACT_APP_COGNITO_AWS_COOKIE_DOMAIN || "",
      secure: true,
    },
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  API: {
    endpoints: [
      {
        name: "mpLeads",
        endpoint: process.env.REACT_APP_SUBMISSION_API_URL || "",
        region: process.env.REACT_APP_SUBMISSION_API_REGION || "",
      },
    ],
  },
  Storage: {
    bucket: process.env.REACT_APP_STORAGE_BUCKET || "" ,
    region: process.env.REACT_APP_STORAGE_REGION || "",
  },
};
