import React, { useState } from "react";
import { LogoBandarKinrara } from "../images";

function ContactUs() {
  const [contactVisibility, setContactVisibility] = useState(false);
  const [emailVisibility, setEmailVisibility] = useState(false);

  return (
    <>
      <span className="contact-us">Contact Us</span>
      <div className="mt-3">
        <div className="d-flex align-items-center">
          <img
            alt="Logo Starling"
            src={LogoBandarKinrara}
            width="50px"
            style={{ verticalAlign: "middle" }}
          />
          <span className="sub-info">Rimbun 3 @ Bandar Kinrara</span>
        </div>
        <button
          className="grey-outline-button mt-4 w-100"
          onClick={() => setContactVisibility(true)}
        >
          {contactVisibility ? (
            <a href="tel:+60380829525">+603-8082 9525</a>
          ) : (
            "Contact"
          )}
        </button>
        <button
          className="grey-outline-button mt-3 w-100"
          onClick={() => setEmailVisibility(true)}
        >
          {emailVisibility ? (
            <a href="mailto:bandarkinrara@spsetia.com">bandarkinrara@spsetia.com</a>
          ) : (
            "Email"
          )}
        </button>
      </div>
    </>
  );
}

export default ContactUs;
