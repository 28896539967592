import React, { useState, useLayoutEffect, useContext } from "react";
import $ from "jquery";
import Contexts from "../../../contexts";
import {
  Registration,
  Balloting,
  UnitSelection,
  CheckSquare,
  UncheckSquare,
} from "../../../images";
import { useHistory } from "react-router";
import { useCookies } from "react-cookie";

function ProcessModal() {
  const history = useHistory();
  const { isRegistered } = useContext(Contexts);

  const [cookies, setCookies] = useCookies(["isProcessTermsAccepted"]);

  const [checked, setChecked] = useState(
    cookies.isProcessTermsAccepted === "true" ? true : false
  );

  useLayoutEffect(() => {
    if (!checked) {
      $("#processModalControl").click();
    }
  }, []);

  function proceed() {
    if (checked) {
      setCookies("isProcessTermsAccepted", true);
      $("#closeModal").click();
    }
  }

  return (
    <>
      <button
        id="processModalControl"
        type="button"
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#processModal"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade"
        id="processModal"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content p-3">
            <div className="modal-header border-0 d-block">
              <h5 style={InlineStyles.title} className="modal-title">
                Our Process
              </h5>
              <h6 style={InlineStyles.subTitle}>
                Please get familiar with the process flow for our e-Balloting
                and unit selection before proceeding to registration
              </h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <img alt="Registration" src={Registration} width="25px" />
                    <span style={InlineStyles.pointTitle} className="ml-3">
                      1. Registration
                    </span>
                  </div>
                  <div className="mt-4">
                    <p style={InlineStyles.paragraph}>
                      Register for an entry by completing the registration form
                      with required documents uploaded by{" "}
                      <b>11:59pm, 26th July 2020</b>
                    </p>
                    <p style={InlineStyles.paragraph}>
                      <b>Documents required:</b>
                    </p>
                    <ul style={InlineStyles.paragraph} className="pl-3">
                      <li>Picture of purchaser IC (front and back)</li>
                      <li>
                        Bank draft or cheque of RM5,000 to "Perumahan Kinrara
                        Berhad"
                      </li>
                    </ul>
                    <p style={InlineStyles.paragraph}>
                      Upon successful registration an email confirmation will be
                      sent to your email address with the Ballot ID used for the
                      draw.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <img alt="Registration" src={Balloting} width="25px" />
                    <span style={InlineStyles.pointTitle} className="ml-3">
                      2. Balloting
                    </span>
                  </div>
                  <div className="mt-4">
                    <p style={InlineStyles.paragraph}>
                      Balloting session will be held <b>12pm, 29th July 2020</b>{" "}
                      where Ballot ID will be drawn to determine the timeslot
                      for unit selection
                    </p>
                    <p style={InlineStyles.paragraph}>
                      8 registrants will be chosen to purchase Rimbun 3.
                      Additional 16 registrants will be selected as replacement
                      for any sales termination from 8 balloters.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <img alt="Registration" src={UnitSelection} width="25px" />
                    <span style={InlineStyles.pointTitle} className="ml-3">
                      3. Unit Selection
                    </span>
                  </div>
                  <div className="mt-4">
                    <p style={InlineStyles.paragraph}>
                      On <b>30th June 2020</b>, each of the successful
                      candiadtes will need to be present at the allocated time
                      at Bandar Kinrara Welcome Centre.
                    </p>
                    <p style={InlineStyles.paragraph}>
                      You will only be given <b>15 minutes</b> to select your
                      unit. Please bring along your ID & Bank Draft/Personal
                      Cheque as well.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center d-flex flex-column">
              <button
                id="closeModal"
                type="button"
                className="btn d-none"
                data-dismiss="modal"
              >
                Close
              </button>
              <div
                className="d-flex my-3"
                onClick={() => {
                  setChecked(!checked);
                }}
                style={{ cursor: "pointer" }}
              >
                {checked ? (
                  <img src={CheckSquare} width={18} height={18} />
                ) : (
                  <img src={UncheckSquare} width={18} height={18} />
                )}
                <span style={InlineStyles.agreeText} className="ml-3">
                  I agree on the process flow for SP Setia e-Balloting and unit
                  selection
                </span>
              </div>
              <div className="d-flex">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="grey-outline-button mx-2 px-5"
                  onClick={() => {
                    setTimeout(() => {
                      history.goBack();
                    }, 500);
                  }}
                >
                  Cancel
                </button>
                {checked ? (
                  <button
                    disabled={!checked}
                    type="button"
                    onClick={proceed}
                    className="blue-button mx-2 px-5"
                  >
                    <span style={InlineStyles.proceedText}>Proceed</span>
                  </button>
                ) : (
                  <div
                    className="btn mx-2 px-5"
                    style={{
                      padding: 13,
                      backgroundColor: "#F4F5F7",
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: 14,
                      color: "#DBDDDE",
                    }}
                  >
                    <span>Proceed</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProcessModal;

const InlineStyles = {
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 19,
    color: "#00233B",
  },
  subTitle: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    marginTop: 10,
    color: "#00233B",
  },
  pointTitle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 15,
    color: "#00233B",
  },
  paragraph: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    color: "#596975",
  },
  agreeText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    color: "#596975",
  },
  proceedText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    color: "#FFFFFF",
  },
};
