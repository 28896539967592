import React, { useState, useEffect } from "react";
import moment from "moment";

const then = moment("2020-07-29 23:59:59", "YYYY-MM-DD HH:mm:ss");

function CountdownTimer() {
  const [countdown, setCountdown] = useState(
    moment.duration(then.diff(moment()))
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(moment.duration(then.diff(moment())));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (countdown.asSeconds() > 0) {
    return (
      <div className=" d-flex flex-column" style={{ minWidth: 210 }}>
        <span className="font10">Time Remaining to Unit Selection</span>
        <span className="time">
          {`${countdown._data.days}d `}
          {`${countdown._data.hours}h 
          ${countdown._data.minutes}m ${countdown._data.seconds}s`}
        </span>
      </div>
    );
  } else {
    return null;
  }
}

export default CountdownTimer;
