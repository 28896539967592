import React from "react";
import {
  fitSelection,
  fitToViewer,
  INITIAL_VALUE,
  ReactSVGPanZoom,
  TOOL_PAN,
  zoomOnViewerCenter,
} from "react-svg-pan-zoom";
import { ReactSvgPanZoomLoader } from "react-svg-pan-zoom-loader";
import { MasterLayoutSVG } from "../../../images";
export default class MasterLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tool: TOOL_PAN,
      value: INITIAL_VALUE,
      ContainerWidth: null,
    };
  }

  Viewer = null;
  Container = null;

  componentDidMount() {
    this.setState({ ContainerWidth: this.Container.offsetWidth }, () => {
      this.fitToViewer_2();
    });
  }

  changeTool(nextTool) {
    this.setState({ tool: nextTool });
  }

  changeValue(nextValue) {
    this.setState({ value: nextValue });
  }

  fitToViewer_1() {
    this.setState((state) => ({ value: fitToViewer(state.value) }));
  }

  fitToViewer_2() {
    this.Viewer.fitToViewer();
  }

  fitSelection_1() {
    this.setState((state) => ({
      value: fitSelection(state.value, 40, 40, 200, 200),
    }));
  }

  fitSelection_2() {
    this.Viewer.fitSelection(40, 40, 200, 200);
  }

  zoomOnViewerCenter_1() {
    this.setState((state) => ({ value: zoomOnViewerCenter(state.value, 1.1) }));
  }

  zoomOnViewerCenter_2() {
    this.Viewer.zoomOnViewerCenter(1);
  }

  render() {
    return (
      <div ref={(Container) => (this.Container = Container)}>
        {this.state.ContainerWidth ? (
          <ReactSvgPanZoomLoader
            src={MasterLayoutSVG}
            render={(content) => (
              <ReactSVGPanZoom
                miniatureProps={{ position: "none" }}
                width={this.state.ContainerWidth}
                height={this.state.ContainerWidth * 0.6}
                ref={(Viewer) => (this.Viewer = Viewer)}
                tool={this.state.tool}
                onChangeTool={(tool) => this.changeTool(tool)}
                value={this.state.value}
                onChangeValue={(value) => this.changeValue(value)}
                onZoom={(e) => console.log("zoom")}
                onPan={(e) => console.log("pan")}
                onClick={(event) =>
                  console.log("click", event.x, event.y, event.originalEvent)
                }
              >
                <svg width="2880" height="1725">
                  {content}
                </svg>
              </ReactSVGPanZoom>
            )}
          />
        ) : null}
      </div>
    );
  }
}
