import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
// import Slider from "react-slick";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCaretLeft,
//   faCaretRight,
//   faFileAlt,
// } from "@fortawesome/free-solid-svg-icons";
import {
  FloorplanIcon,
  TypeAGroundFloor,
  TypeAFirstFloor,
  TypeACGroundFloor,
  TypeACFirstFloor,
} from "../../../images";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactUs from "../../../components/contactUs";
import CountdownTimer from "../../../components/countdownTimer";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function Description() {
  const unlockPricingDate = moment("2022-06-10");
  const location = useLocation();
  useEffect(() => {
    const { scrollToTop } = location;
    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <div className="d-flex flex-column">
            <h1 className="title">Rimbun 3, Double Storey Terrace</h1>
            <span className="sub-info">Bandar Kinrara</span>
            <span className="sub-info-small">Residential</span>
            <span className="font10" style={{ paddingTop: 13 }}>
              From
            </span>
            <span className="date-title">RM 983,000</span>
          </div>
          <hr className="mt-4 mb-4" />
        </div>
        <div className="col-md-3 mb-3 mb-md-0">
          <CountdownTimer />
        </div>
      </div>
      <div className="row">
        <div className="col-md-9" style={{ paddingBottom: 14 }}>
          <span className="contact-us">Project Description</span>
          <ul style={{ paddingInlineStart: 20, paddingTop: 15 }}>
            <li className="sub-info">Good highway and road accessibility</li>
            <li className="sub-info">
              Strategic location: located at Bandar Kinrara
            </li>
            <li className="sub-info">
              Matured township into neighbourhood and premier living
            </li>
            <li className="sub-info">Quality finishes</li>
          </ul>
          <hr className="mt-4 mb-4" />
          <span className="contact-us">Specifications</span>
          <div className="row">
            <div className="col-md-6 d-flex flex-column">
              <span
                className="upload-image"
                style={{ paddingTop: 13, color: "#596975" }}
              >
                Built up area
              </span>
              <span className="sub-info">2,230 sq. ft - 2,658 sq. ft</span>
              <span
                className="upload-image"
                style={{ paddingTop: 13, color: "#596975" }}
              >
                Tenure
              </span>
              <span className="sub-info">Freehold</span>
              <span
                className="upload-image"
                style={{ paddingTop: 13, color: "#596975" }}
              >
                Phase
              </span>
              <span className="sub-info">Rimbun 3 , Double Storey Terrace</span>
            </div>
            <div className="col-md-6 d-flex flex-column">
              <span
                className="upload-image"
                style={{ paddingTop: 23, color: "#596975" }}
              >
                Land Area
              </span>
              <span className="sub-info">1,638 sq. ft - 4,822 sq. ft</span>
              <span
                className="upload-image"
                style={{ paddingTop: 13, color: "#596975" }}
              >
                Township
              </span>
              <span className="sub-info">Bandar Kinrara</span>
              <span
                className="upload-image"
                style={{ paddingTop: 13, color: "#596975" }}
              >
                Completion Date
              </span>
              <span className="sub-info">July 2022</span>
            </div>
          </div>
          <hr className="mt-4 mb-4" />
          <span className="contact-us">Layouts</span>
          <div id="table-box">
            <table className="table-spacing" width="100%">
              <thead>
                <tr>
                  <th className="date-title">Layout Type</th>
                  <th className="date-title">Size (sq ft)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-title="Layout Type" className="background-grey">
                    <div className="layout-title">Type A</div>
                  </td>
                  <td data-title="Size (sq ft)" className="background-grey">
                    <span className="layout-title">2,230</span>
                  </td>
                  <td data-title="Floor Plan" className="background-grey">
                    <div
                      data-toggle="modal"
                      data-target="#TypeALayout"
                      className="virtual-tour d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <img src={FloorplanIcon} width={20} className="mr-3" />
                      Floor plan
                    </div>
                  </td>
                </tr>
                <tr>
                  <td data-title="Layout Type" className="background-grey">
                    <div className="layout-title">Type AC1</div>
                  </td>
                  <td data-title="Size (sq ft)" className="background-grey">
                    <span className="layout-title">2,658</span>
                  </td>
                  <td data-title="Floor Plan" className="background-grey">
                    <div
                      data-toggle="modal"
                      data-target="#TypeACLayout"
                      className="virtual-tour d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <img src={FloorplanIcon} width={20} className="mr-3" />{" "}
                      Floor plan
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <hr className="mt-4 mb-4" />
          <span className="contact-us">Documents</span>
          <div className="row mt-3">
            <div className="col-12">
              <span className="sub-info">
                e-Brochure will be sent to you once you have received the APDL
                number
              </span>
            </div>
            <div className="col-md-4 col-12">
              <a
                href={`${process.env.PUBLIC_URL}/images/Phase12A_Starling.pdf`}
                target="_blank"
              >
                <img className="img-fluid" src={ImgBrochure} />
              </a>
            </div>
          </div> */}
          <hr className="mt-4 mb-4" />
          <div className="d-flex flex-column">
            <span className="contact-us">Location</span>
            <span className="sub-info" style={{ paddingTop: 13 }}>
              Rimbun 3, Double Storey Terrace @ Bandar Kinrara
            </span>
            <div className="mt-3 sub-info font-weight-bold">
              Google map coordinates
            </div>
            <div className="sub-info" style={{ textDecoration: "underline" }}>
              3,038786 , 101,677932
            </div>
            <div className="mt-3 embed-responsive  embed-responsive-16by9">
              <iframe
                className="w-100 h-100"
                frameBorder="0"
                style={{ border: 0 }}
                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_KEY}&q=3.0389551,101.6778351&zoom=17`}
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-3 mt-md-0">
          <ContactUs />
        </div>
      </div>
      <TypeALayout />
      <TypeACLayout />
    </>
  );
}

function TypeALayout() {
  const [activeTab, setActiveTab] = useState("ground");
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, 1000);
  }, []);
  return (
    <div className="modal fade" id="TypeALayout" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header border-0 d-block">
            <h5 style={InlineStyles.title} className="modal-title">
              Floor plan for Type A1 layout
            </h5>
            <div className="d-flex">
              <div
                onClick={() => setActiveTab("ground")}
                style={
                  activeTab === "ground"
                    ? InlineStyles.activeTab
                    : InlineStyles.tab
                }
              >
                Ground Floor
              </div>
              <div
                onClick={() => setActiveTab("first")}
                style={
                  activeTab === "first"
                    ? InlineStyles.activeTab
                    : InlineStyles.tab
                }
              >
                First Floor
              </div>
              <div style={InlineStyles.tabEndLine}></div>
            </div>
          </div>
          <div className="modal-body">
            <TransformWrapper defaultScale={1}>
              <TransformComponent>
                <div className="col">
                  {isReady ? (
                    <img
                      src={
                        activeTab === "ground"
                          ? TypeAGroundFloor
                          : TypeAFirstFloor
                      }
                      className="img-fluid"
                    />
                  ) : null}
                </div>
              </TransformComponent>
            </TransformWrapper>
          </div>
          <div className="modal-footer border-0">
            <div className="d-flex">
              <button
                type="button"
                data-dismiss="modal"
                className="blue-button mx-2 px-5"
              >
                <span style={InlineStyles.proceedText}>Done</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function TypeACLayout() {
  const [activeTab, setActiveTab] = useState("ground");
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, 1000);
  }, []);
  return (
    <div className="modal fade" id="TypeACLayout" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header border-0 d-block">
            <h5 style={InlineStyles.title} className="modal-title">
              Floor plan for Type AC1 layout
            </h5>
            <div className="d-flex">
              <div
                onClick={() => setActiveTab("ground")}
                style={
                  activeTab === "ground"
                    ? InlineStyles.activeTab
                    : InlineStyles.tab
                }
              >
                Ground Floor
              </div>
              <div
                onClick={() => setActiveTab("first")}
                style={
                  activeTab === "first"
                    ? InlineStyles.activeTab
                    : InlineStyles.tab
                }
              >
                First Floor
              </div>
              <div style={InlineStyles.tabEndLine}></div>
            </div>
          </div>
          <div className="modal-body">
            <TransformWrapper defaultScale={1}>
              <TransformComponent>
                <div className="col">
                  {isReady ? (
                    <img
                      src={
                        activeTab === "ground"
                          ? TypeACGroundFloor
                          : TypeACFirstFloor
                      }
                      className="img-fluid"
                    />
                  ) : null}
                </div>
              </TransformComponent>
            </TransformWrapper>
          </div>
          <div className="modal-footer border-0">
            <div className="d-flex">
              <button
                type="button"
                data-dismiss="modal"
                className="blue-button mx-2 px-5"
              >
                <span style={InlineStyles.proceedText}>Done</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Description;

const InlineStyles = {
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 19,
    color: "#00233B",
  },
  proceedText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    color: "#FFFFFF",
  },
  tab: {
    cursor: "pointer",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    padding: "20px 25px",
    color: "#586976",
    borderBottom: "2px solid #F5F6F7",
  },
  activeTab: {
    cursor: "pointer",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    padding: "20px 25px",
    color: "#49B3B1",
    borderBottom: "2px solid #49B3B1",
  },
  tabEndLine: {
    flex: 1,
    borderBottom: "2px solid #F5F6F7",
  },
};
