import { createContext } from "react";

const Context = createContext({
  user: {},
  setUser: () => {},
  customer: {},
  setCustomer: () => {},
  isRegistered: {},
  setIsRegistered: () => {},
  opportunity: {},
  setOpportunity: () => {},
});

export default Context;
