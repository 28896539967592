import React from "react";

function TermsModal() {
  return (
    <div
      className="modal fade"
      id="termsModal"
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content p-3">
          <div className="modal-header border-0 d-block">
            <h5 style={InlineStyles.title} className="modal-title">
              TERMS & CONDITIONS
            </h5>
            <h6 style={InlineStyles.subTitle}>
              Welcome to Perumahan Kinrara Berhad(collectively referred to
              herein as "PKB", "we," "us," or "our") Facebook Page.
            </h6>
          </div>
          <div className="modal-body">
            <ul>
              <li className="mb-3" style={InlineStyles.paragraph}>
                All copyright, trademarks, and other intellectual property
                rights used as part of this Facebook pageare vested in PKB. You
                acquire no rights in the Facebook pageother than the limited
                right to use the pagein accordance with these terms. You are
                permitted to print out or download information and content from
                this Facebook pagefor your own personal, non-commercial use. You
                may not offer for sale or sell or distribute over any medium,
                any part of this Facebook pageor its content. You may not make
                any part of the Facebook pageavailable as part of another
                website, whether by hyperlink framing on the Internet or
                otherwise unless you have been authorised to do so in writing by
                PKB
              </li>
              <li className="mb-3" style={InlineStyles.paragraph}>
                PKBreserves the right at any time and without notice to enhance,
                modify, alter, suspend or permanently discontinue all or any
                part of this Facebook pageand to restrict or prohibit access to
                it.
              </li>
              <li className="mb-3" style={InlineStyles.paragraph}>
                PKBdoes not warrant in any way, whether expressly or impliedly,
                the accuracy, reliability and completeness of the information
                and contents of this Facebook page. In no event, will PKBor any
                of its subsidiary or associated companies or related
                corporations or their directors and employees accept any
                liability with regard to your use of this Facebook pageand/or to
                the information contained in the website. Your sole remedy is to
                discontinue using this page.
              </li>
              <li className="mb-3" style={InlineStyles.paragraph}>
                This Facebook pageand its contents do not in any way constitute
                any offer or invitation in respect of the shares in and
                securities of PKBor any other company.
              </li>
              <li className="mb-3" style={InlineStyles.paragraph}>
                If you click on a link to a third party site, PKBcannot accept
                responsibility for any use of your personal information by such
                third parties, and we cannot guarantee that they will adhere to
                the same privacy and security practices as PKB. We encourage you
                to review the privacy policies of any other service provider
                from whom you request services. If you visit a third party
                website that is linked to PKBsite/Facebook page, you should
                consult the site's privacy policy before providing your personal
                information.
              </li>
              <li className="mb-3" style={InlineStyles.paragraph}>
                We reserve the right at our sole discretion to make changes in
                this policy without prior notice.
              </li>
              <li className="mb-3" style={InlineStyles.paragraph}>
                These terms and conditions and the content of this Facebook page
                are governed by Malaysian law, and Malaysian courts shall have
                exclusive jurisdiction in any dispute.
              </li>
            </ul>
          </div>
          <div className="modal-footer border-0 justify-content-center d-flex flex-column">
            <button className="blue-button" data-dismiss="modal">
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsModal;

const InlineStyles = {
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 19,
    color: "#00233B",
  },
  subTitle: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    marginTop: 10,
    color: "#00233B",
  },
  pointTitle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 15,
    color: "#00233B",
  },
  paragraph: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    color: "#596975",
  },
  agreeText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    color: "#596975",
  },
  proceedText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    color: "#FFFFFF",
  },
};
