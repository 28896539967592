import React, { useState, useContext, useRef } from "react";
import IntlTelInput from "react-intl-tel-input";
import DatePicker from "react-datepicker";
import moment from "moment";
import Contexts from "../../../contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  BirthdayCalendar,
  Trash,
  CircleCheckedBlack,
  ArrowDown,
  ArrowUp,
} from "../../../images";
import { S3UploadFile } from "../../../libs/api";

function Purchaser({
  purchaser,
  no,
  show,
  onTrashButtonPress,
  onTextChange,
  onObjectChange,
}) {
  const [isShow, setIsShow] = useState(show);
  const [isIcFrontSubmitting, setIsIcFrontSubmitting] = useState(false);
  const [isIcBackSubmitting, setIsIcBackSubmitting] = useState(false);
  const [
    isPaymentDocumentSubmitting,
    setIsPaymentDocumentSubmitting,
  ] = useState(false);

  const icFrontInputRef = useRef(null);
  const icBackInputRef = useRef(null);
  const paymentDocumentInputRef = useRef(null);

  const { user } = useContext(Contexts);

  const onFileChange = async (file, field, no) => {
    const { key } = await S3UploadFile(
      file,
      user.attributes["custom:company_id"],
      user.username,
      user.attributes["custom:lead_customer_id"],
      field
    );

    onTextChange(
      field,
      { key, bucket: process.env.REACT_APP_STORAGE_BUCKET },
      no
    );

    if (field === "ic_front") {
      setIsIcFrontSubmitting(false);
    } else if (field === "ic_back") {
      setIsIcBackSubmitting(false);
    } else {
      setIsPaymentDocumentSubmitting(false);
    }
  };

  return (
    <div className="mt-3">
      {/* <div className="btn-purchase d-flex">
        <div className="d-flex flex-fill">
          <span
            style={{
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            Purchaser {no + 1}
          </span>
        </div>
        <div className="d-flex">
          <div className="ml-auto d-flex align-items-center">
            {onTrashButtonPress ? (
              <button
                type="button"
                style={{ width: 30 }}
                onClick={() => onTrashButtonPress(no - 1)}
                className="btn btn-link mr-2 p-0"
              >
                <img src={Trash} width={15} alt="delete-icon" />
              </button>
            ) : null}
            <button
              style={{ width: 30 }}
              onClick={() => setIsShow(!isShow)}
              className="btn p-0"
              type="button"
              data-toggle="collapse"
              data-target={`#purchaser${no}`}
              aria-expanded="false"
              aria-controls={`purchaser${no}`}
            >
              {isShow ? (
                <img src={ArrowDown} width={15} alt="arrow down" />
              ) : (
                <img src={ArrowUp} width={15} alt="arrow up" />
              )}
            </button>
          </div>
        </div>
      </div> */}
      <div
        className={`collapse multi-collapse ${show ? "show" : ""}`}
        id={`purchaser${no}`}
      >
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Identity type<span className="asterisk">*</span>
              </label>
              <select
                required
                disabled
                type="text"
                className="form-control"
                name="identity_type"
                value={purchaser.identity_type}
                onChange={(e) =>
                  onTextChange(e.target.name, e.target.value, no)
                }
              >
                <option value="">Please Select</option>
                <option value="1">MyKad (NRIC)</option>
                <option value="2">Passport</option>
                <option value="3">Military / Police</option>
                <option value="4">Company Registration Number</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                {purchaser.identity_type == "1"
                  ? "MyKad (NRIC)"
                  : purchaser.identity == "2"
                  ? "Passport"
                  : purchaser.identity == "3"
                  ? "Military / Police"
                  : purchaser.identity == "4"
                  ? "Company Registration Number"
                  : "Identity"}
                <span className="asterisk">*</span>
              </label>
              <input
                required
                type="text"
                className="form-control"
                name="identity"
                value={purchaser.identity}
                onChange={(e) =>
                  onTextChange(e.target.name, e.target.value, no)
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Title <span className="asterisk">*</span>
              </label>
              <select
                required
                className="form-control"
                placeholder="Select"
                name="title"
                value={purchaser.title}
                onChange={(e) =>
                  onTextChange(e.target.name, e.target.value, no)
                }
              >
                <option value="">Please Select</option>
                <option>Mr.</option>
                <option>Ms.</option>
                <option>Mrs.</option>
                <option>Dr.</option>
                <option>JP</option>
                <option>Pehin</option>
                <option>Datin</option>
                <option>Datuk</option>
                <option>Dato'</option>
                <option>Dato</option>
                <option>Dato Sri</option>
                <option>Tan Sri</option>
                <option>Tun</option>
                <option>Not Applicable</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Full name <span className="asterisk">*</span>
              </label>
              <input
                required
                type="text"
                name="name"
                className="form-control"
                value={purchaser.name}
                onChange={(e) =>
                  onTextChange(e.target.name, e.target.value, no)
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Prefered name <span className="asterisk">*</span>
              </label>
              <input
                required
                type="text"
                className="form-control"
                name="preferred_name"
                value={purchaser.preferred_name}
                onChange={(e) =>
                  onTextChange(e.target.name, e.target.value, no)
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Nationality <span className="asterisk">*</span>
              </label>
              <select
                required
                type="text"
                className="form-control"
                name="nationality_country_code"
                value={purchaser.nationality_country_code}
                onChange={(e) =>
                  onTextChange(e.target.name, e.target.value, no)
                }
              >
                <option value="">Please Select</option>
                <option value="AF">Afghanistan</option>
                <option value="AL">Albania</option>
                <option value="DZ">Algeria</option>
                <option value="AS">American Samoa</option>
                <option value="AD">Andorra</option>
                <option value="AO">Angola</option>
                <option value="AG">Antigua and Barbuda</option>
                <option value="AR">Argentina</option>
                <option value="AM">Armenia</option>
                <option value="AU">Australia</option>
                <option value="AT">Austria</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BS">Bahamas</option>
                <option value="BH">Bahrain</option>
                <option value="BD">Bangladesh</option>
                <option value="BB">Barbados</option>
                <option value="BW">Botswana</option>
                <option value="BY">Belarus</option>
                <option value="BE">Belgium</option>
                <option value="BZ">Belize</option>
                <option value="BJ">Benin</option>
                <option value="BT">Bhutan</option>
                <option value="BO">Bolivia</option>
                <option value="BA">Bosnia and Herzegovina</option>
                <option value="BR">Brazil</option>
                <option value="IO">British Indian Ocean Territory</option>
                <option value="BN">Brunei Darussalam</option>
                <option value="BG">Bulgaria</option>
                <option value="BF">Burkina Faso</option>
                <option value="BI">Burundi</option>
                <option value="KH">Cambodia</option>
                <option value="CM">Cameroon</option>
                <option value="CA">Canada</option>
                <option value="CV">Cabo Verde</option>
                <option value="CF">Central African Republic</option>
                <option value="TD">Chad</option>
                <option value="CL">Chile</option>
                <option value="CN">China</option>
                <option value="CO">Colombia</option>
                <option value="KM">Comoros</option>
                <option value="CD">Congo, Democratic Republic of the</option>
                <option value="CR">Costa Rica</option>
                <option value="HR">Croatia</option>
                <option value="CU">Cuba</option>
                <option value="CY">Cyprus</option>
                <option value="CZ">Czechia</option>
                <option value="DK">Denmark</option>
                <option value="DJ">Djibouti</option>
                <option value="DM">Dominica</option>
                <option value="EC">Ecuador</option>
                <option value="EG">Egypt</option>
                <option value="GQ">Equatorial Guinea</option>
                <option value="ER">Eritrea</option>
                <option value="EE">Estonia</option>
                <option value="ET">Ethiopia</option>
                <option value="FJ">Fiji</option>
                <option value="FI">Finland</option>
                <option value="FR">France</option>
                <option value="GA">Gabon</option>
                <option value="GM">Gambia</option>
                <option value="GE">Georgia</option>
                <option value="DE">Germany</option>
                <option value="GH">Ghana</option>
                <option value="GR">Greece</option>
                <option value="GD">Grenada</option>
                <option value="GT">Guatemala</option>
                <option value="GW">Guinea-Bissau</option>
                <option value="GN">Guinea</option>
                <option value="GG">Guernsey</option>
                <option value="HT">Haiti</option>
                <option value="HN">Honduras</option>
                <option value="HU">Hungary</option>
                <option value="IS">Iceland</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IR">Iran (Islamic Republic of)</option>
                <option value="IQ">Iraq</option>
                <option value="IE">Ireland</option>
                <option value="IL">Israel</option>
                <option value="IT">Italy</option>
                <option value="JM">Jamaica</option>
                <option value="JP">Japan</option>
                <option value="JO">Jordan</option>
                <option value="KZ">Kazakhstan</option>
                <option value="KE">Kenya</option>
                <option value="KW">Kuwait</option>
                <option value="KG">Kyrgyzstan</option>
                <option value="LV">Latvia</option>
                <option value="LB">Lebanon</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libya</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="LU">Luxembourg</option>
                <option value="MW">Malawi</option>
                <option value="MY">Malaysia</option>
                <option value="MV">Maldives</option>
                <option value="ML">Mali</option>
                <option value="MH">Marshall Islands</option>
                <option value="MR">Mauritania</option>
                <option value="MU">Mauritius</option>
                <option value="MX">Mexico</option>
                <option value="FM">Micronesia (Federated States of)</option>
                <option value="MD">Moldova, Republic of</option>
                <option value="MC">Monaco</option>
                <option value="MN">Mongolia</option>
                <option value="MA">Morocco</option>
                <option value="MZ">Mozambique</option>
                <option value="NA">Namibia</option>
                <option value="NR">Nauru</option>
                <option value="NP">Nepal</option>
                <option value="NZ">New Zealand</option>
                <option value="NI">Nicaragua</option>
                <option value="NG">Nigeria</option>
                <option value="KR">Korea, Republic of</option>
                <option value="NO">Norwegian</option>
                <option value="OM">Oman</option>
                <option value="PH">Philippines</option>
                <option value="PK">Pakistan</option>
                <option value="PW">Palau</option>
                <option value="PA">Panama</option>
                <option value="PG">Papua New Guinea</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Peru</option>
                <option value="PL">Poland</option>
                <option value="PT">Portugal</option>
                <option value="QA">Qatar</option>
                <option value="RO">Romania</option>
                <option value="RU">Russian Federation</option>
                <option value="RW">Rwanda</option>
                <option value="LC">Saint Lucia</option>
                <option value="WS">Samoa</option>
                <option value="MF">San Marino</option>
                <option value="ST">Sao Tome and Principe</option>
                <option value="SA">Saudi Arabia</option>
                <option value="SN">Senegal</option>
                <option value="RS">Serbia</option>
                <option value="SC">Seychelles</option>
                <option value="SL">Sierra Leone</option>
                <option value="SG">Singapore</option>
                <option value="SK">Slovakia</option>
                <option value="SI">Slovenia</option>
                <option value="SB">Solomon Islands</option>
                <option value="SO">Somalia</option>
                <option value="ZA">South Africa</option>
                <option value="ES">Spain</option>
                <option value="LK">Sri Lanka</option>
                <option value="SD">Sudan</option>
                <option value="SR">Suriname</option>
                <option value="SE">Sweden</option>
                <option value="CH">Switzerland</option>
                <option value="SY">Syrian Arab Republic</option>
                <option value="TW">Taiwan</option>
                <option value="TJ">Tajikistan</option>
                <option value="TZ">Tanzania, United Republic of</option>
                <option value="TH">Thailand</option>
                <option value="TG">Togo</option>
                <option value="TO">Tonga</option>
                <option value="TT">Trinidad and Tobago</option>
                <option value="TN">Tunisia</option>
                <option value="TR">Turkey</option>
                <option value="TV">Tuvalu</option>
                <option value="UG">Uganda</option>
                <option value="UA">Ukraine</option>
                <option value="UY">Uruguay</option>
                <option value="UZ">Uzbekistan</option>
                <option value="VE">Venezuela (Bolivarian Republic of)</option>
                <option value="VN">Viet Nam</option>
                <option value="EH">Western Sahara</option>
                <option value="YE">Yemen</option>
                <option value="ZM">Zambia</option>
                <option value="ZW">Zimbabwe</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Mobile <span className="asterisk">*</span>
              </label>
              <IntlTelInput
                formatOnInit={false}
                containerClassName="intl-tel-input"
                inputClassName="form-control"
                preferredCountries={["my"]}
                defaultCountry={
                  purchaser.mobile_country_code
                    ? purchaser.mobile_country_code.toLowerCase()
                    : null
                }
                placeholder="123456789"
                separateDialCode={true}
                value={purchaser.mobile}
                onPhoneNumberChange={(validation, number, country) => {
                  onObjectChange(
                    {
                      mobile_prefix: country.dialCode,
                      mobile_country_code: country.iso2.toUpperCase(),
                      mobile: number,
                    },
                    no
                  );
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Email <span className="asterisk">*</span>
              </label>
              <input
                required
                type="email"
                className="form-control"
                name="email"
                value={purchaser.email}
                onChange={(e) =>
                  onTextChange(e.target.name, e.target.value, no)
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Gender <span className="asterisk">*</span>
              </label>
              <select
                required
                type="text"
                className="form-control"
                name="gender"
                value={purchaser.gender}
                onChange={(e) =>
                  onTextChange(e.target.name, e.target.value, no)
                }
              >
                <option value="">Please Select</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Race <span className="asterisk">*</span>
              </label>
              <select
                required
                className="form-control"
                name="race"
                value={purchaser.race}
                onChange={(e) =>
                  onTextChange(e.target.name, e.target.value, no)
                }
              >
                <option value="">Please Select</option>
                <option value="bumiputera">Bumiputera</option>
                <option value="chinese">Chinese</option>
                <option value="indian">Indian</option>
                <option value="others">Others</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Marital Status <span className="asterisk">*</span>
              </label>
              <select
                required
                className="form-control"
                name="marital_status"
                value={purchaser.marital_status}
                onChange={(e) =>
                  onTextChange(e.target.name, e.target.value, no)
                }
              >
                <option value="">Please select</option>
                <option value="married">Married</option>
                <option value="single">Single</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Date of birth <span className="asterisk">*</span>
              </label>
              <div className="input-group">
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="YYYY-MM-DD"
                  selected={new Date()}
                  onChange={(date) =>
                    onTextChange(
                      "birth_date",
                      moment(date).format("YYYY-MM-DDTHH:mm:ssZ"),
                      no
                    )
                  }
                  className="form-control"
                  value={
                    purchaser.birth_date
                      ? moment(purchaser.birth_date).format("YYYY-MM-DD")
                      : ""
                  }
                />
                <div className="input-group-append">
                  <img
                    width={16}
                    src={BirthdayCalendar}
                    aria-hidden="true"
                    alt="Calendar"
                    className="calendar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Address <span className="asterisk">*</span>
              </label>
              <input
                required
                type="text"
                className="form-control"
                name="address"
                value={purchaser.address}
                onChange={(e) => {
                  onTextChange("address", e.target.value, no);
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                City <span className="asterisk">*</span>
              </label>
              <input
                required
                className="form-control"
                name="city"
                value={purchaser.city}
                onChange={(e) =>
                  onTextChange(e.target.name, e.target.value, no)
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Postcode <span className="asterisk">*</span>
              </label>
              <input
                required
                type="text"
                className="form-control"
                name="postcode"
                value={purchaser.postcode}
                onChange={(e) =>
                  onTextChange(e.target.name, e.target.value, no)
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                State <span className="asterisk">*</span>
              </label>
              <select
                required
                className="form-control"
                name="state"
                value={purchaser.state}
                onChange={(e) =>
                  onTextChange(e.target.name, e.target.value, no)
                }
              >
                <option value="">Please Select</option>
                <option>Kuala Lumpur</option>
                <option>Selangor</option>
                <option>Johor</option>
                <option>Penang</option>
                <option>Kedah</option>
                <option>Kelantan</option>
                <option>Malacca</option>
                <option>Negeri Sembilan</option>
                <option>Pahang</option>
                <option>Perak</option>
                <option>Perlis</option>
                <option>Sabah</option>
                <option>Sarawak</option>
                <option>Terengganu</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 order-2">
            <div className="form-group">
              <label className="label-title">
                Country <span className="asterisk">*</span>
              </label>
              <select
                required
                className="form-control"
                name="country_code"
                value={purchaser.country_code}
                onChange={(e) =>
                  onTextChange(e.target.name, e.target.value, no)
                }
              >
                <option value="">Please Select</option>
                <option value="AF">Afghanistan</option>
                <option value="AL">Albania</option>
                <option value="DZ">Algeria</option>
                <option value="AS">American Samoa</option>
                <option value="AD">Andorra</option>
                <option value="AO">Angola</option>
                <option value="AG">Antigua and Barbuda</option>
                <option value="AR">Argentina</option>
                <option value="AM">Armenia</option>
                <option value="AU">Australia</option>
                <option value="AT">Austria</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BS">Bahamas</option>
                <option value="BH">Bahrain</option>
                <option value="BD">Bangladesh</option>
                <option value="BB">Barbados</option>
                <option value="BW">Botswana</option>
                <option value="BY">Belarus</option>
                <option value="BE">Belgium</option>
                <option value="BZ">Belize</option>
                <option value="BJ">Benin</option>
                <option value="BT">Bhutan</option>
                <option value="BO">Bolivia</option>
                <option value="BA">Bosnia and Herzegovina</option>
                <option value="BR">Brazil</option>
                <option value="IO">British Indian Ocean Territory</option>
                <option value="BN">Brunei Darussalam</option>
                <option value="BG">Bulgaria</option>
                <option value="BF">Burkina Faso</option>
                <option value="BI">Burundi</option>
                <option value="KH">Cambodia</option>
                <option value="CM">Cameroon</option>
                <option value="CA">Canada</option>
                <option value="CV">Cabo Verde</option>
                <option value="CF">Central African Republic</option>
                <option value="TD">Chad</option>
                <option value="CL">Chile</option>
                <option value="CN">China</option>
                <option value="CO">Colombia</option>
                <option value="KM">Comoros</option>
                <option value="CD">Congo, Democratic Republic of the</option>
                <option value="CR">Costa Rica</option>
                <option value="HR">Croatia</option>
                <option value="CU">Cuba</option>
                <option value="CY">Cyprus</option>
                <option value="CZ">Czechia</option>
                <option value="DK">Denmark</option>
                <option value="DJ">Djibouti</option>
                <option value="DM">Dominica</option>
                <option value="EC">Ecuador</option>
                <option value="EG">Egypt</option>
                <option value="GQ">Equatorial Guinea</option>
                <option value="ER">Eritrea</option>
                <option value="EE">Estonia</option>
                <option value="ET">Ethiopia</option>
                <option value="FJ">Fiji</option>
                <option value="FI">Finland</option>
                <option value="FR">France</option>
                <option value="GA">Gabon</option>
                <option value="GM">Gambia</option>
                <option value="GE">Georgia</option>
                <option value="DE">Germany</option>
                <option value="GH">Ghana</option>
                <option value="GR">Greece</option>
                <option value="GD">Grenada</option>
                <option value="GT">Guatemala</option>
                <option value="GW">Guinea-Bissau</option>
                <option value="GN">Guinea</option>
                <option value="GG">Guernsey</option>
                <option value="HT">Haiti</option>
                <option value="HN">Honduras</option>
                <option value="HU">Hungary</option>
                <option value="IS">Iceland</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IR">Iran (Islamic Republic of)</option>
                <option value="IQ">Iraq</option>
                <option value="IE">Ireland</option>
                <option value="IL">Israel</option>
                <option value="IT">Italy</option>
                <option value="JM">Jamaica</option>
                <option value="JP">Japan</option>
                <option value="JO">Jordan</option>
                <option value="KZ">Kazakhstan</option>
                <option value="KE">Kenya</option>
                <option value="KW">Kuwait</option>
                <option value="KG">Kyrgyzstan</option>
                <option value="LV">Latvia</option>
                <option value="LB">Lebanon</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libya</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="LU">Luxembourg</option>
                <option value="MW">Malawi</option>
                <option value="MY">Malaysia</option>
                <option value="MV">Maldives</option>
                <option value="ML">Mali</option>
                <option value="MH">Marshall Islands</option>
                <option value="MR">Mauritania</option>
                <option value="MU">Mauritius</option>
                <option value="MX">Mexico</option>
                <option value="FM">Micronesia (Federated States of)</option>
                <option value="MD">Moldova, Republic of</option>
                <option value="MC">Monaco</option>
                <option value="MN">Mongolia</option>
                <option value="MA">Morocco</option>
                <option value="MZ">Mozambique</option>
                <option value="NA">Namibia</option>
                <option value="NR">Nauru</option>
                <option value="NP">Nepal</option>
                <option value="NZ">New Zealand</option>
                <option value="NI">Nicaragua</option>
                <option value="NG">Nigeria</option>
                <option value="KR">Korea, Republic of</option>
                <option value="NO">Norwegian</option>
                <option value="OM">Oman</option>
                <option value="PH">Philippines</option>
                <option value="PK">Pakistan</option>
                <option value="PW">Palau</option>
                <option value="PA">Panama</option>
                <option value="PG">Papua New Guinea</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Peru</option>
                <option value="PL">Poland</option>
                <option value="PT">Portugal</option>
                <option value="QA">Qatar</option>
                <option value="RO">Romania</option>
                <option value="RU">Russian Federation</option>
                <option value="RW">Rwanda</option>
                <option value="LC">Saint Lucia</option>
                <option value="WS">Samoa</option>
                <option value="MF">San Marino</option>
                <option value="ST">Sao Tome and Principe</option>
                <option value="SA">Saudi Arabia</option>
                <option value="SN">Senegal</option>
                <option value="RS">Serbia</option>
                <option value="SC">Seychelles</option>
                <option value="SL">Sierra Leone</option>
                <option value="SG">Singapore</option>
                <option value="SK">Slovakia</option>
                <option value="SI">Slovenia</option>
                <option value="SB">Solomon Islands</option>
                <option value="SO">Somalia</option>
                <option value="ZA">South Africa</option>
                <option value="ES">Spain</option>
                <option value="LK">Sri Lanka</option>
                <option value="SD">Sudan</option>
                <option value="SR">Suriname</option>
                <option value="SE">Sweden</option>
                <option value="CH">Switzerland</option>
                <option value="SY">Syrian Arab Republic</option>
                <option value="TW">Taiwan</option>
                <option value="TJ">Tajikistan</option>
                <option value="TZ">Tanzania, United Republic of</option>
                <option value="TH">Thailand</option>
                <option value="TG">Togo</option>
                <option value="TO">Tonga</option>
                <option value="TT">Trinidad and Tobago</option>
                <option value="TN">Tunisia</option>
                <option value="TR">Turkey</option>
                <option value="TV">Tuvalu</option>
                <option value="UG">Uganda</option>
                <option value="UA">Ukraine</option>
                <option value="UY">Uruguay</option>
                <option value="UZ">Uzbekistan</option>
                <option value="VE">Venezuela (Bolivarian Republic of)</option>
                <option value="VN">Viet Nam</option>
                <option value="EH">Western Sahara</option>
                <option value="YE">Yemen</option>
                <option value="ZM">Zambia</option>
                <option value="ZW">Zimbabwe</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                IC Front <span className="asterisk">*</span>
              </label>
              <button
                type="button"
                className="upload-image-container form-control"
                onClick={(e) => {
                  icFrontInputRef.current.click();
                }}
              >
                {isIcFrontSubmitting ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : purchaser.ic_front ? (
                  <>
                    <img src={CircleCheckedBlack} alt="checked" width={20} />
                    <span className="upload-image-uploaded ml-3">Uploaded</span>
                    <span className="upload-image ml-3">Change</span>
                  </>
                ) : (
                  <span className="upload-image">Upload Image</span>
                )}
                <input
                  ref={icFrontInputRef}
                  accept=".png, .jpg, .jpeg"
                  className="d-none"
                  type="file"
                  name="image"
                  onChange={async (e) => {
                    setIsIcFrontSubmitting(true);
                    onFileChange(e.target.files[0], "ic_front", no);
                  }}
                />
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                IC Back <span className="asterisk">*</span>
              </label>
              <button
                type="button"
                className="upload-image-container form-control"
                onClick={(e) => {
                  icBackInputRef.current.click();
                }}
              >
                {isIcBackSubmitting ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : purchaser.ic_back ? (
                  <>
                    <img src={CircleCheckedBlack} alt="checked" width={20} />
                    <span className="upload-image-uploaded ml-3">Uploaded</span>
                    <span className="upload-image ml-3">Change</span>
                  </>
                ) : (
                  <span className="upload-image">Upload Image</span>
                )}
                <input
                  ref={icBackInputRef}
                  accept=".png, .jpg, .jpeg"
                  className="d-none"
                  type="file"
                  name="image"
                  onChange={async (e) => {
                    setIsIcBackSubmitting(true);
                    onFileChange(e.target.files[0], "ic_back", no);
                  }}
                />
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title mb-0">
                Proof of Payment <span className="asterisk">*</span>
              </label>
              <label
                className="d-block"
                style={{
                  fontSize: 11,
                  fontFamily: "Open Sans",
                  color: "#596975",
                }}
              >
                <span className="asterisk">* </span>Bank draft or cheque of
                RM5,000 to "Perumahan Kinrara Berhad"
              </label>
              <button
                type="button"
                className="upload-image-container form-control"
                onClick={(e) => {
                  paymentDocumentInputRef.current.click();
                }}
              >
                {isPaymentDocumentSubmitting ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : purchaser.payment_document ? (
                  <>
                    <img src={CircleCheckedBlack} alt="checked" width={20} />
                    <span className="upload-image-uploaded ml-3">Uploaded</span>
                    <span className="upload-image ml-3">Change</span>
                  </>
                ) : (
                  <span className="upload-image">Upload Image</span>
                )}
                <input
                  ref={paymentDocumentInputRef}
                  className="d-none"
                  type="file"
                  name="image"
                  onChange={async (e) => {
                    setIsPaymentDocumentSubmitting(true);
                    onFileChange(e.target.files[0], "payment_document", no);
                  }}
                />
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Cheque number <span className="asterisk">*</span>
              </label>
              <input
                required
                type="text"
                name="cheque_number"
                className="form-control"
                value={purchaser.cheque_number}
                onChange={(e) =>
                  onTextChange(e.target.name, e.target.value, no)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Purchaser;
