import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import _ from "lodash";
import { CookiesProvider } from "react-cookie";

import Contexts from "./contexts";
import Listing from "./pages/listing";
import Detail from "./pages/detail";
import Register from "./pages/register";
import Thankyou from "./pages/register/thankyou";
import { amplifyConfig } from "../config/amplify";
import { getCustomer, getOpportunities } from "./libs/api";
Amplify.configure(amplifyConfig);

export default function App() {
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [isRegistered, setIsRegistered] = useState();
  const [opportunity, setOpportunity] = useState();

  const value = {
    user,
    setUser,
    customer,
    setCustomer,
    isRegistered,
    setIsRegistered,
    opportunity,
    setOpportunity,
  };

  useEffect(() => {
    async function CheckAuth() {
      let res;
      try {
        res = await Auth.currentAuthenticatedUser();
        // console.log('Cognito User',res)
        setUser(res);
        const customer_res = await getCustomer(
          res.attributes["custom:lead_customer_id"]
        );
        setCustomer(customer_res);
        const opp = await getOpportunities(
          res.attributes["custom:lead_customer_id"]
        );
        const currentOpps = _.filter(opp, {
          project_id: process.env.REACT_APP_PROJECT_ID,
        });
        if (!_.isEmpty(currentOpps)) {
          setIsRegistered(true);
          setOpportunity(currentOpps);
        }
        setLoading(false);
      } catch (err) {
        if (err !== "not authenticated") {
          console.log("@Error", err);
        } else {
          console.log("Not authenticated");
        }
        setLoading(false);
      }
    }

    CheckAuth();
  }, []);

  if (loading) {
    return <div />;
  }

  if (process.env.NODE_ENV === "development" || user) {
    return (
      <Contexts.Provider value={value}>
        <CookiesProvider>
          <Router>
            <Switch>
              <Route exact path="/">
                <Listing />
              </Route>
              <Route path="/:id/detail/register">
                <Register />
              </Route>
              <Route path="/:id/detail/thanks">
                <Thankyou />
              </Route>
              <Route path="/:id/detail">
                <Detail />
              </Route>
            </Switch>
          </Router>
        </CookiesProvider>
      </Contexts.Provider>
    );
  } else {
    return (
      <div className="container">
        <div className="row">
          <div className="col vh-100 d-flex justify-content-center align-items-center">
            <p>Login Required</p>
          </div>
        </div>
      </div>
    );
  }
}
