import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { Calendar, Video, MapMarker } from "../images";
import Contexts from "../contexts";

function UnitSelectionTime({ showLinks }) {
  const { isRegistered, opportunity } = useContext(Contexts);

  const [unitSelectionStartTime, setUnitSelectionStartTime] = useState(null);
  const [unitSelectionEndTime, setUnitSelectionEndTime] = useState(null);
  const [unitSelectionVideoUrl, setUnitSelectionVideoUrl] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(opportunity)) {
      _.map(opportunity, (booking) => {
        if (!_.isEmpty(booking.appointments)) {
          setUnitSelectionStartTime(
            moment(booking.appointments[0]["starting_at"]).format("H:mmA")
          );
          setUnitSelectionEndTime(
            moment(booking.appointments[0]["ending_at"]).format("H:mmA")
          );
          setUnitSelectionVideoUrl(booking.appointments[0].meeting_url);
        }
      });
    }
  }, [opportunity]);

  const renderLinks = () => {
    if (isRegistered && showLinks) {
      return (
        <div className="col-12 col-md-8 d-flex align-items-center">
          <div className="row">
            <div className="col-12 col-sm-auto">
              <div className="dropdown">
                <button
                  className="btn btn-link dropdown-toggle pl-0"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img alt="Calendar" src={Calendar} width={24} />
                  <span className="mx-1 dropdown-title">Add to Calendar</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    className="dropdown-item dropdown-option"
                    href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20200808T023000Z%2F20200808T043000Z&details=Unit%20Selection%20for%20Rimbun%203%20at%20Bandar%20Kinrara%20Welcome%20Centre%2C%20you%20will%20be%20given%2015%20minutes%20to%20select%20your%20ideal%20unit.%0A%0Ahttps%3A%2F%2Fwaze.com%2Ful%2Fhw2832gpuk&location=Bandar%20Kinrara%20Welcome%20Centre%2C%20Jalan%20BK%205a%2F1%2C%20Bandar%20Kinrara%2C%2047180%20Puchong%2C%20Selangor%2C%20Malaysia&text=Unit%20Selection%20for%20Rimbun%203%2C%20Double%20Storey%20%40%20Bandar%20Kinrara"
                    target="_blank"
                  >
                    Google Calendar
                  </a>
                  <a
                    className="dropdown-item dropdown-option"
                    href="http://box.mhub.my/media/2020-08-03-Setia_Unit_Selection.ics"
                    // href={`${process.env.PUBLIC_URL}/ics/Bandar_Rimbayu_Online_Balloting_13.ics`}
                    download
                  >
                    Outlook Calendar (.ics)
                  </a>
                  <a
                    className="dropdown-item dropdown-option"
                    href="https://calendar.yahoo.com/?desc=Unit%20Selection%20for%20Rimbun%203%20at%20Bandar%20Kinrara%20Welcome%20Centre%2C%20you%20will%20be%20given%2015%20minutes%20to%20select%20your%20ideal%20unit.%0A%0Ahttps%3A%2F%2Fwaze.com%2Ful%2Fhw2832gpuk&et=20200808T043000Z&in_loc=Bandar%20Kinrara%20Welcome%20Centre%2C%20Jalan%20BK%205a%2F1%2C%20Bandar%20Kinrara%2C%2047180%20Puchong%2C%20Selangor%2C%20Malaysia&st=20200808T023000Z&title=Unit%20Selection%20for%20Rimbun%203%2C%20Double%20Storey%20%40%20Bandar%20Kinrara&v=60"
                    target="_blank"
                  >
                    Yahoo Calendar
                  </a>
                </div>
              </div>
            </div>
            {isRegistered ? (
              <>
                <div
                  className="d-none d-lg-block"
                  style={{ border: "1px solid #DBDDDE" }}
                ></div>
                <div className="col-12 col-sm-auto">
                  <a
                    className="btn btn-link pl-0"
                    target="_blank"
                    href="https://waze.com/ul/hw2832gpuk"
                  >
                    <img alt="Video" src={MapMarker} width={15} />
                    <span className="mx-1 dropdown-title"> View In Waze</span>
                  </a>
                </div>
              </>
            ) : null}
          </div>
        </div>
      );
    }
  };
  return (
    <div className="row">
      <div className="col-auto">
        <div className="d-flex flex-column align-items-center justify-content-center date-box">
          <span className="month">Aug</span>
          <span className="date">8</span>
        </div>
      </div>
      <div className="col">
        <div className="row">
          <div className="col-12 col-md">
            <div className="d-flex flex-column justify-content-center">
              <span className="date-title">Unit Selection</span>
              <span className="sub-info">
                {unitSelectionStartTime
                  ? `${unitSelectionStartTime} - ${unitSelectionEndTime}`
                  : "Only successfully selected candidate from balloting will be allocated a unit selection timeslot"}
              </span>
              {unitSelectionStartTime ? (
                <span className="sub-info">Bandar Kinrara Welcome Center</span>
              ) : null}
            </div>
          </div>
          {renderLinks()}
        </div>
      </div>
    </div>
  );
}

export default UnitSelectionTime;

UnitSelectionTime.defaultProps = {
  showLinks: true,
};
