import React, { useContext } from "react";
import { Route, Link, useLocation, useRouteMatch } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Banner1, Banner2, Banner3, Banner4 } from "../../images";
import Contexts from "../../contexts";
import Description from "./tabs/description";
import Siteplan from "./tabs/siteplan";
import OnlineBalloting from "./tabs/onlineBalloting";
import MasterLayout from "./tabs/masterLayout";
import { isMobile } from "react-device-detect";

function Detail() {
  const { isRegistered } = useContext(Contexts);
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <div className="container-fluid" style={{ marginBottom: 100 }}>
      <div className="row">
        <div className="col px-0">
          <Carousel showThumbs={false} showArrows={false} swipeable={true}>
            <img
              alt="Bandar Rimbayu @ Banner 1"
              className="img-fluid"
              src={Banner1}
            />
            <img
              alt="Bandar Rimbayu @ Banner 2"
              className="img-fluid"
              src={Banner2}
            />
            <img
              alt="Bandar Rimbayu @ Banner 3"
              className="img-fluid"
              src={Banner3}
            />
            <img
              alt="Bandar Rimbayu @ Banner 4"
              className="img-fluid"
              src={Banner4}
            />
          </Carousel>
        </div>
      </div>
      <div className="row">
        <div className="col px-0">
          <div className="d-flex tab">
            <div
              className={`d-flex align-items-center detail-link ${
                location.pathname === "/1/detail" ? "active" : ""
              }`}
            >
              <Link
                to={{ pathname: "/1/detail" }}
                className="d-flex align-items-center"
                style={{ letterSpacing: 0.5 }}
              >
                DESCRIPTION
              </Link>
            </div>
            <div
              className={`d-flex align-items-center detail-link ${
                location.pathname === "/1/detail/site-plan" ? "active" : ""
              }`}
            >
              {isMobile ? (
                <a
                  href={process.env.REACT_APP_SITEPLAN_URL}
                  target="_blank"
                  className="d-flex align-items-center"
                  style={{ letterSpacing: 0.5 }}
                >
                  SITEPLAN
                </a>
              ) : (
                <Link
                  to={{ pathname: "/1/detail/site-plan" }}
                  className="d-flex align-items-center"
                  style={{ letterSpacing: 0.5 }}
                >
                  SITEPLAN
                </Link>
              )}
            </div>
            <div
              className={`d-flex align-items-center detail-link ${
                location.pathname === "/1/detail/master-layout" ? "active" : ""
              }`}
            >
              <Link
                to={{ pathname: "/1/detail/master-layout" }}
                className="d-flex align-items-center"
                style={{ letterSpacing: 0.5 }}
              >
                MASTER LAYOUT
              </Link>
            </div>
            <div
              className={`d-flex align-items-center text-center detail-link ${
                location.pathname === "/1/detail/online-balloting"
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to={{ pathname: "/1/detail/online-balloting" }}
                style={{ letterSpacing: 0.5 }}
              >
                BALLOTING
              </Link>
            </div>
            <div className={`d-flex flex-fill detail-link`} />
          </div>
        </div>
      </div>
      <div className="row" style={{ marginTop: "2rem" }}>
        <div className="col mx-2">
          <Route exact path={`${path}`} component={Description} />
          <Route path={`${path}/site-plan`} component={Siteplan} />
          <Route path={`${path}/master-layout`} component={MasterLayout} />
          <Route
            path={`${path}/online-balloting`}
            component={OnlineBalloting}
          />
        </div>
      </div>
    </div>
  );
}

export default Detail;
