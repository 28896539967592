import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Contexts from "../../contexts";
import { TopView } from "../../images";
import { Calendar, Video, MapMarker } from "../../images";

function Listing() {
  const { isRegistered, opportunity, customer } = useContext(Contexts);
  const [unitSelectionStartTime, setUnitSelectionStartTime] = useState(null);
  const [unitSelectionEndTime, setUnitSelectionEndTime] = useState(null);
  const [unitSelectionVideoUrl, setUnitSelectionVideoUrl] = useState(null);
  const history = useHistory();
  const view = (e) => {
    if (isRegistered) {
      history.push("/1/detail");
    } else {
      history.push("/1/detail/online-balloting");
    }
  };

  useEffect(() => {
    if (!_.isEmpty(opportunity)) {
      _.map(opportunity, (booking) => {
        if (!_.isEmpty(booking.appointments)) {
          setUnitSelectionStartTime(
            moment(booking.appointments[0]["starting_at"]).format("H:mmA")
          );
          setUnitSelectionEndTime(
            moment(booking.appointments[0]["ending_at"]).format("H:mmA")
          );
          setUnitSelectionVideoUrl(booking.appointments[0].meeting_url);
        }
      });
    }
  }, [opportunity]);

  return (
    <div className="container-fluid mt-3" style={{ marginBottom: 100 }}>
      <div className="row">
        <div className="col">
          <h1 className="title">Online Balloting</h1>
          <span className="sub-info">Total 1 found</span>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <div className="card shadow">
            <img
              onClick={view}
              style={{ cursor: "pointer" }}
              alt="Bandar Rimbayu Top View"
              src={TopView}
              className="card-img-top"
            />
            <div className="card-body">
              <h5
                onClick={view}
                style={{ cursor: "pointer" }}
                className="card-title"
              >
                Online Balloting and Unit Selection for Rimbun 3, Double Storey
                Terrace @ Bandar Kinrara
              </h5>
              <div className="d-flex">
                <div onClick={view} style={{ cursor: "pointer" }}>
                  <div className="d-flex flex-column align-items-center justify-content-center date-box">
                    <span className="month">Aug</span>
                    <span className="date">7</span>
                  </div>
                </div>
                <div
                  onClick={view}
                  style={{ cursor: "pointer" }}
                  className="d-flex ml-2 flex-column justify-content-center"
                >
                  <span className="date-title">Online Balloting</span>
                  <span className="sub-info">11:00AM - 12:00PM</span>
                  {isRegistered ? (
                    <span className="sub-info">
                      Ballot code:{" "}
                      <b className="text-uppercase">
                        {customer.contact.ballot_id}
                      </b>
                    </span>
                  ) : null}
                </div>
                {isRegistered ? (
                  <div className="d-flex flex-fill justify-content-end">
                    <div className="dropdown d-flex align-items-center">
                      <button
                        className="btn btn-link"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img alt="Calendar" src={Calendar} width={24} />
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a
                          className="dropdown-item dropdown-option"
                          href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20200807T030000Z%2F20200807T040000Z&details=Watch%20on%20Facebook%0Ahttps%3A%2F%2Fwww.facebook.com%2Fspsetia%2F&text=Rimbun%203%2C%20Double%20Storey%20Terrace%20%40%20Bandar%20Kinrara%20Online%20Balloting"
                          target="_blank"
                        >
                          Google Calendar
                        </a>
                        <a
                          className="dropdown-item dropdown-option"
                          href="http://box.mhub.my/media/2020-08-03-Setia_Online_balloting.ics"
                          // href={`${process.env.PUBLIC_URL}/ics/Bandar_Rimbayu_Online_Balloting.ics`}
                          download
                        >
                          Outlook Calendar (.ics)
                        </a>
                        <a
                          className="dropdown-item dropdown-option"
                          href="https://calendar.yahoo.com/?desc=Watch%20on%20Facebook%0Ahttps%3A%2F%2Fwww.facebook.com%2Fspsetia%2F&et=20200807T040000Z&st=20200807T030000Z&title=Rimbun%203%2C%20Double%20Storey%20Terrace%20%40%20Bandar%20Kinrara%20Online%20Balloting&v=60"
                          target="_blank"
                        >
                          Yahoo Calendar
                        </a>
                      </div>
                    </div>
                    <a
                      className="btn btn-link d-flex align-items-center"
                      target="_blank"
                      href="https://www.facebook.com/inpgrpsb/videos/305174430818088"
                    >
                      <img alt="Video" src={Video} width={24} />
                    </a>
                  </div>
                ) : null}
              </div>
              <div className="d-flex mt-4">
                <div onClick={view} style={{ cursor: "pointer" }}>
                  <div className="d-flex flex-column align-items-center justify-content-center date-box">
                    <span className="month">Aug</span>
                    <span className="date">8</span>
                  </div>
                </div>
                <div
                  onClick={view}
                  style={{ cursor: "pointer" }}
                  className="d-flex ml-2 flex-column justify-content-center"
                >
                  <span className="date-title">Unit Selection</span>
                  <span className="sub-info">
                    {unitSelectionStartTime
                      ? `${unitSelectionStartTime} - ${unitSelectionEndTime}`
                      : "Only successfully selected candidate from balloting will be allocated a unit selection timeslot"}
                  </span>
                  {unitSelectionStartTime ? (
                    <span className="sub-info">
                      Bandar Kinrara Welcome Center
                    </span>
                  ) : null}
                </div>
                {isRegistered ? (
                  <div className="d-flex flex-fill justify-content-end">
                    <div className="dropdown d-flex align-items-center">
                      <button
                        className="btn btn-link"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img alt="Calendar" src={Calendar} width={24} />
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a
                          className="dropdown-item dropdown-option"
                          href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20200808T023000Z%2F20200808T043000Z&details=Unit%20Selection%20for%20Rimbun%203%20at%20Bandar%20Kinrara%20Welcome%20Centre%2C%20you%20will%20be%20given%2015%20minutes%20to%20select%20your%20ideal%20unit.%0A%0Ahttps%3A%2F%2Fwaze.com%2Ful%2Fhw2832gpuk&location=Bandar%20Kinrara%20Welcome%20Centre%2C%20Jalan%20BK%205a%2F1%2C%20Bandar%20Kinrara%2C%2047180%20Puchong%2C%20Selangor%2C%20Malaysia&text=Unit%20Selection%20for%20Rimbun%203%2C%20Double%20Storey%20%40%20Bandar%20Kinrara"
                          target="_blank"
                        >
                          Google Calendar
                        </a>
                        <a
                          className="dropdown-item dropdown-option"
                          href="http://box.mhub.my/media/2020-08-03-Setia_Unit_Selection.ics"
                          // href={`${process.env.PUBLIC_URL}/ics/Bandar_Rimbayu_Online_Balloting_13.ics`}
                          download
                        >
                          Outlook Calendar (.ics)
                        </a>
                        <a
                          className="dropdown-item dropdown-option"
                          href="https://calendar.yahoo.com/?desc=Unit%20Selection%20for%20Rimbun%203%20at%20Bandar%20Kinrara%20Welcome%20Centre%2C%20you%20will%20be%20given%2015%20minutes%20to%20select%20your%20ideal%20unit.%0A%0Ahttps%3A%2F%2Fwaze.com%2Ful%2Fhw2832gpuk&et=20200808T043000Z&in_loc=Bandar%20Kinrara%20Welcome%20Centre%2C%20Jalan%20BK%205a%2F1%2C%20Bandar%20Kinrara%2C%2047180%20Puchong%2C%20Selangor%2C%20Malaysia&st=20200808T023000Z&title=Unit%20Selection%20for%20Rimbun%203%2C%20Double%20Storey%20%40%20Bandar%20Kinrara&v=60"
                          target="_blank"
                        >
                          Yahoo Calendar
                        </a>
                      </div>
                    </div>
                    {isRegistered ? (
                      <a
                        className="btn btn-link d-flex align-items-center"
                        target="_blank"
                        href="https://waze.com/ul/hw2832gpuk"
                        style={{ paddingLeft: 16, paddingRight: 16 }}
                      >
                        <img alt="Video" src={MapMarker} width={15} />
                      </a>
                    ) : (
                      <div className="btn">
                        <div style={{ width: 24 }}></div>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              {isRegistered ? (
                <Link
                  to={{ pathname: "/1/detail" }}
                  className="btn blue-button mt-3 py-2"
                >
                  View Project
                </Link>
              ) : (
                <Link
                  to={{ pathname: "/1/detail/online-balloting" }}
                  className="btn blue-button mt-3 py-2"
                >
                  Click here to proceed
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Listing;
